<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Schedule Message
		</h1>
		<MessageListScenarios :is_schedule_message="true" />
	</div>
</template>

<script>
import MessageListScenarios from '@/components/scenarios/message_scenarios_list/MessageListScenarios.vue';

export default {
	name: "ScheduleMessage",
	meta: {
		title: 'Alert Cascade - Schedule Message'
	},
	components: {
		MessageListScenarios
	},
};
</script>
<style scoped>
</style>
